import React from 'react';
import toast from 'react-hot-toast';
import './Preguntas.Module.css';
import { isAuthenticated } from '../../utils/usuario/usuario';
import axios from 'axios';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { useMediaQuery } from 'react-responsive';
import {
  Avatar,
  Box,
  Badge,
  Button,
  IconButton,
  Typography,
  FormControlLabel,
  Checkbox,
  Dialog,
Accordion,
AccordionSummary,
AccordionDetails
} from "@mui/material";

function Preguntas() {
	const token = localStorage.getItem('Token');
	const config = {
		headers: {
			xtoken: `${token}`,
		},
	};

	const [loader, setLoader] = React.useState(true);
	const [image, setImage] = React.useState('');
	const [renderKey, setRenderKey] = React.useState(0);
	const isSuperAdmin = isAuthenticated();
	const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
		
const preguntas = [
	{
		pregunta:"¿Cuáles son los pasos a seguir?",
		respuesta:"Debés solicitar una consulta con el Dr. Rodrigo del Pazo a través de nuestro Whatsapp para poder realizarte una evaluación bucal y determinar si sos apto/a para usar alineadores, tiempo de tratamiento y presupuesto."
	},
	{
		pregunta:"¿Cada cuanto tiempo debo realizar los controles?",
		respuesta:"Las visitas varían entre 30 y 60 días, dependiendo el caso."
	},
	{
		pregunta:"¿Puedo ver como quedarán mis dientes antes de comenzar el tratamiento?",
		respuesta:"¡Sí! Con la tecnología de escaneo y modelado 3D, podrás definir junto con tu ortodoncista cómo será el resultado final y ver una simulación de cómo se moverán tus dientes a lo largo del tratamiento."
	},
	{
		pregunta:"¿Cómo funciona la ortodoncia invisible?",
		respuesta:"Los alineadores invisibles mueven progresivamente tus piezas dentales siguiendo cuidadosamente la planificación realizada por el especialista. De acuerdo a cada caso, deberás cambiar de alineadores cada determinado tiempo, que suele ser entre 1 y 2 semanas, hasta finalizar el tratamiento."
	},
	{
		pregunta:"¿Cuánto tiempo se usan los alineadores por día y qué cuidados debo tener?",
		respuesta:"Se usan 22 horas al día (sólo te los retirás para comer y cepillarte los dientes). Podés tomar mate o cualquier infusión caliente. Se lavan con agua fría. Siempre se guardan en la caja que te suministramos. No dejar al alcance de las mascotas."
	},
	{
		pregunta:"¿Qué cuidados debo tener al finalizar el tratamiento?",
		respuesta:"Luego de finalizar el tratamiento, deberás utilizar las placas de retención y seguir las demás recomendaciones de nuestros profesionales."
	},
	{
		pregunta:"¿El tratamiento con alineadores sustituye los brackets fijos?",
		respuesta:"Eso depende. En algunos casos, el tratamiento con alineadores es incluso más aconsejable y predecible que el tratamiento con brackets fijos. Cabe destacar que los alineadores, al igual que los brackets fijos, son una herramienta. Corresponde al ortodoncista saber qué herramienta utilizar para solucionar cada tipo de problema."
	},
	{
		pregunta:"¿Puedo usar alineadores invisibles si tengo implantes, muelas de juicio o me falta un diente?",
		respuesta:"¡Sí! Esa es la gran ventaja de los alineadores. Siempre tené en cuenta que tu boca debe estar sana, sin caries, sarro e infecciones dentales."
	},
	{
		pregunta:"¿Qué pasa si pierdo mi alineador?",
		respuesta:"Si el paciente pierde un alineador, debe informarnos rápidamente, para que podamos producir uno nuevo."
	},
	
	]

	return (
		<Box className="fondoPreguntas" style={{ width: "100vw", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: !isMobile ? "center" : "flex-start", paddingTop: "5vh", paddingBottom: "5vh", position: 'relative', zIndex: 1 }}>
			<Box style={{ width: "50vw", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "flex-start" }}>
				<Typography sx={{ fontFamily: "Nunito", gap: '5px', display: 'flex', fontSize: isMobile ? "18px" : '25px', fontWeight: '400', alignItems: 'center', color: '#0077B6', marginBottom: "20px", paddingLeft: isMobile && "10px", zIndex: 2 }}>
					Preguntas Frecuentes
				</Typography>
			</Box>

			<Box style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
				{preguntas?.map((p, index) => (
					<Accordion key={index} sx={{ borderRadius: "60px !important", backgroundColor: 'transparent', width: !isMobile ? "50vw" : "100vw", '::before': { backgroundColor: 'transparent !important' } }}>
						<AccordionSummary
							expandIcon={<KeyboardArrowRightRoundedIcon sx={{ fontSize: '18px', fill: '#00B4D8 !important' }} />}
							aria-controls="panel1-content"
							id="panel1-header"
							sx={{
								backgroundColor: "#fff",
								boxShadow: "rgba(0, 0, 0, 0.25)",
								border: 'none !important',
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								width: "100%",
								borderRadius: '60px',
								'& .MuiSvgIcon-root': {
									fontSize: '18px',
									color: '#00B4D8 !important'
								}
							}}
						>
							<Box className="shadoww" style={{ width: "100%", justifyContent: "space-between", display: "flex", borderRadius: '60px' }}>
								<Typography sx={{ fontFamily: "Nunito", gap: '5px', display: 'flex', fontSize: isMobile ? "14px" : '19px', fontWeight: '400', alignItems: 'center', color: '#00B4D8' }}>
									{p?.pregunta}
								</Typography>
							</Box>
						</AccordionSummary>
						<AccordionDetails style={{ borderRadius: '60px', backgroundColor: '#0077B6' }}>
							<Box className="shadoww" style={{ borderRadius: '60px', cursor: "pointer", width: "100%", justifyContent: "space-between", display: "flex", padding: '0px 0px 0px 0px' }}>
								<Box style={{ display: "flex", gap: "10px" }}>
									<Typography sx={{ fontFamily: "Nunito", gap: '5px', display: 'flex', fontSize: isMobile ? '14px' : "19px", fontWeight: '400', alignItems: 'center', color: '#fff' }}>
										{p?.respuesta}
									</Typography>
								</Box>
							</Box>
						</AccordionDetails>
					</Accordion>
				))}
			</Box>
		</Box>
	);
}

export default Preguntas;
